import React from "react";
export const SchoolSchedule: React.FC<{ school: string }> = (props) => {
  return props.school === "csm" ? (
    <div className="schedules">
      <hr />
      <ul>
        <li>
          <a href="#first">Zac's Fall '96/Spring '97</a>
        </li>
        <li>
          <a href="#fall97">Zac's Schedule for fall '97</a>
        </li>
        <li>
          <a href="#spring98">Zac's Schedule for spring '98</a>
        </li>
        <li>
          <a href="#fall98">Zac's Schedule for Fall '98</a>
        </li>
        <li>
          <a href="#spring99">Zac's Schedule for spring '99</a>
        </li>
        <li>
          <a href="#fall99">Zac's Schedule for Fall '99</a>
        </li>
        <li>
          <a href="#spring00">Zac's Schedule for spring 2000</a>
        </li>
        <li>
          <a href="#total">Zac's Totals for EE and CS degrees</a>
        </li>
      </ul>
      <hr />
      <hr />
      <section id="first" />
      <h3>Fall of 1996</h3>
      <ul>
        <li>Circuits I- EGGN381</li>
        <li>Physics I (Mechanics)- PHGN132</li>
        <li>Quant Lab- CHGN126</li>
        <li>EPICS I</li>
        <li>Geology- GEOL101</li>
        <li>PAGN101</li>
        <li>Freshmen Success Seminar</li>
        <li>16(19)</li>
      </ul>
      <hr />
      <h3>Spring of 1997</h3>
      <ul>
        <li>Calculus III- MAGN231</li>
        <li>Differential Equations- MAGN315</li>
        <li>Physics II (E&amp;M)- PHGN231</li>
        <li>Statics- EGGN241</li>
        <li>EPICS II</li>
        <li>Circuits Lab- EGGN383</li>
        <li>Intro to Economics- EBGN111</li>
        <li>PAGN102</li>
        <li>23(23)</li>
      </ul>
      <hr />
      <section id="fall97" />
      <table>
        <caption>Zac's Schedule for Fall of '97 [21.5(21.5)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
          <th>Fri</th>
        </tr>
        <tr>
          <td>9am</td>
          <td>Strengths</td>
          <td rowSpan={2}>War</td>
          <td>Strengths</td>
          <td rowSpan={2}>War</td>
          <td>Strengths</td>
        </tr>
        <tr>
          <td>10am</td>
          <td>Thermos</td>
          <td>Thermo</td>
          <td>Thermo</td>
        </tr>
        <tr>
          <td>11am</td>
          <td>Tennis</td>
          <td />
          <td>Tennis</td>
          <td />
          <td />
        </tr>
        <tr>
          <td>Noon</td>
          <td colSpan={5}>Lunch</td>
        </tr>
        <tr>
          <td>1pm</td>
          <td>ES200</td>
          <td>EG295</td>
          <td>ES200</td>
          <td />
          <td>ES200</td>
        </tr>
        <tr>
          <td>2pm</td>
          <td>Lin Alg</td>
          <td></td>
          <td>Lin Alg</td>
          <td></td>
          <td>Lin Alg</td>
        </tr>
        <tr>
          <td>6-9pm</td>
          <td>LISS398D</td>
          <td colSpan={4}></td>
        </tr>
      </table>
      <hr />

      <section id="spring98" />
      <table>
        <caption>Zac's Schedule for Spring of 1998 [19(19)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
          <th>Fri</th>
        </tr>
        <tr>
          <td>8am</td>
          <td>C++</td>
          <td>C++</td>
          <td>C++</td>
          <td />
          <td>C++</td>
        </tr>
        <tr>
          <td>9am</td>
          <td>Circuits II</td>
          <td rowSpan={3}>Power Lab</td>
          <td>Circuits II</td>
          <td />
          <td>Circuits II</td>
        </tr>
        <tr>
          <td>10am</td>
          <td>Elec Machines</td>
          <td>Elec Machines</td>
          <td />
          <td>Elec Machines</td>
        </tr>
        <tr>
          <td>11am</td>
          <td>Fluids</td>
          <td>Fluids</td>
          <td />
          <td>Fluids</td>
        </tr>
        <tr>
          <td>Noon</td>
          <td>Lunch</td>
          <td>Lunch</td>
          <td>Lunch</td>
          <td></td>
          <td>Lunch</td>
        </tr>
        <tr>
          <td>1pm</td>
          <td>Feedback Systems</td>
          <td></td>
          <td>Feedback Systems</td>
          <td></td>
          <td>Feedback Systems</td>
        </tr>
        <tr>
          <td>2pm</td>
          <td rowSpan={3}>Circuits II Lab</td>
          <td rowSpan={3}>Elec Mach Lab</td>
        </tr>
        <tr>
          <td>3pm</td>
        </tr>
        <tr>
          <td>4pm</td>
        </tr>
      </table>
      <section id="fall98" />
      <hr />
      <table>
        <caption>Zac's Schedule for Fall of 1998 [21(21)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
          <th>Fri</th>
        </tr>
        <tr>
          <td>8am</td>
          <td />
          <td rowSpan={3}>
            <a href="http://www.mines.edu/Academic/courses/eng/EGGN491/">
              Senior Design
            </a>
          </td>
        </tr>
        <tr>
          <td>9am</td>
          <td>
            <a href="http://www.mines.edu/fs_home/hking/h400s98.htm">CS400</a>
          </td>
          <td>CS400</td>
          <td></td>
          <td>CS400</td>
        </tr>
        <tr>
          <td>10am</td>
          <td>
            <a href="http://power.mines.edu/PowSys/">EG484</a>
          </td>
          <td>EG484</td>
          <td></td>
          <td>EG484</td>
        </tr>
        <tr>
          <td>11am</td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/eng/EGGN483/">
              EG483
            </a>
          </td>
          <td></td>
          <td>EG483</td>
          <td></td>
          <td>EG483</td>
        </tr>
        <tr>
          <td>Noon</td>
          <td>Lab</td>
          <td></td>
          <td>Lab</td>
          <td></td>
          <td>Lab</td>
        </tr>
        <tr>
          <td>1pm</td>
          <td>
            <a href="http://www.physics.mines.edu/undergrad_pgm/classes/PH314/index.html">
              PH314
            </a>
          </td>
          <td rowSpan={3}>EG481 Lab</td>
          <td>PH314</td>
          <td></td>
          <td>PH314</td>
        </tr>
        <tr>
          <td>2pm</td>
          <td>Macro</td>
          <td>Macro</td>
          <td></td>
          <td>Macro</td>
        </tr>
        <tr>
          <td>3pm</td>
          <td>
            <a href="http://silurian.mines.edu/~whoff/courses/eggn481/">
              EG481
            </a>
          </td>
          <td>EG481</td>
          <td></td>
          <td>EG481</td>
        </tr>
      </table>
      <hr />
      <section id="spring99" />
      <table>
        <caption>Zac's Schedule for Spring of 1999 [18(18)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
          <th>Fri</th>
        </tr>
        <tr>
          <td>8am</td>
          <td />
          <td rowSpan={3}>EGGN385 LAB</td>
          <td />
          <td rowSpan={3}>Senior Design II</td>
          <td />
        </tr>
        <tr>
          <td>9am</td>
          <td>CS341</td>
          <td>CS341</td>
          <td>CS341</td>
        </tr>
        <tr>
          <td>10am</td>
          <td>EG482</td>
          <td>EG482</td>
          <td>EG482</td>
        </tr>
        <tr>
          <td>11am</td>
          <td>EG385</td>
          <td></td>
          <td>EG385</td>
          <td></td>
          <td>EG385</td>
        </tr>
        <tr>
          <td>Noon</td>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td>1pm</td>
          <td>CS358</td>
          <td></td>
          <td>CS358</td>
          <td></td>
          <td>CS358</td>
        </tr>
        <tr>
          <td>2pm</td>
        </tr>
        <tr>
          <td>3pm</td>
        </tr>
        <tr>
          <td>4pm</td>
          <td>Eng. Econ</td>
          <td></td>
          <td>Eng. Econ</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>5pm</td>
        </tr>
        <tr>
          <td>6pm</td>
          <td></td>
          <td></td>
          <td rowSpan={3}>EGGN482 LAB</td>
          <td />
          <td />
        </tr>
        <tr>
          <td>7pm</td>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td>8pm</td>
          <td />
          <td />
          <td />
          <td />
        </tr>
      </table>
      <hr />
      <section id="fall99" />
      <table>
        <caption>Zac's Schedule for Fall of 1999 [19(19)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
          <th>Fri</th>
        </tr>
        <tr>
          <td>8am</td>
          <td></td>
          <td rowSpan={2}>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs306/">
              CS306- HJ214
            </a>
          </td>
          <td></td>
          <td rowSpan={2}>CS306- HJ214</td>
        </tr>
        <tr>
          <td>9am</td>
          <td>CS323- HH202</td>
          <td>CS323- HH202</td>
          <td>CS323- HH202</td>
        </tr>
        <tr>
          <td>10am</td>
          <td>CS403- AH140</td>
          <td></td>
          <td>CS403- AH140</td>
          <td></td>
          <td>CS403- AH140</td>
        </tr>
        <tr>
          <td>11am</td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs461/">
              CS461- HJ212
            </a>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Noon</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>1pm</td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs406/">
              CS406- HJ209
            </a>
          </td>
          <td>LI498-SH102</td>
          <td>CS406- HJ209</td>
          <td>LI498-SH102</td>
          <td>CS406- HJ209</td>
        </tr>
        <tr>
          <td>2pm</td>
          <td>
            <a href="http://gauss.mines.edu/~jwang/fall99/macs407.html">
              CS407- GC210N
            </a>
          </td>
          <td></td>
          <td>CS407- GC210N</td>
          <td></td>
          <td>CS407- GC210N</td>
        </tr>
      </table>

      <section id="spring00" />

      <hr />
      <table>
        <caption>Zac's Schedule for Spring 2000 [7(7)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
          <th>Fri</th>
        </tr>
        <tr>
          <td>10am</td>
          <td></td>
          <td></td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs462/">
              CS462- HJ212
            </a>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>11am</td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs441/">
              CS441-SH104
            </a>
          </td>
          <td></td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs441/">
              CS441-SH104
            </a>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Noon</td>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td>1pm</td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs442/">
              CS442- HJ213
            </a>
          </td>
          <td></td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs442/">
              CS442- HJ213
            </a>
          </td>
          <td></td>
          <td>
            <a href="http://www.mines.edu/Academic/courses/math_cs/macs442/">
              CS442- HJ213
            </a>
          </td>
        </tr>
      </table>
      <hr />
      <section id="total" />
      <h3>Totals 1996-2000</h3>
      <table>
        <tr>
          <th>Semester</th>
          <th> Hours Completed</th>
        </tr>
        <tr>
          <td>Fall96</td>
          <td>16</td>
        </tr>
        <tr>
          <td>Srping97</td>
          <td>23</td>
        </tr>
        <tr>
          <td>Fall97</td>
          <td>21.5</td>
        </tr>
        <tr>
          <td>Spring98</td>
          <td>19</td>
        </tr>
        <tr>
          <td>Fall98</td>
          <td>21</td>
        </tr>
        <tr>
          <td>Spring99</td>
          <td>18</td>
        </tr>
        <tr>
          <td>Fall99</td>
          <td>19</td>
        </tr>
        <tr>
          <td>Spring00</td>
          <td>7</td>
        </tr>
        <tr>
          <td>AP/Tested Out</td>
          <td>25</td>
        </tr>
        <tr>
          <td>
            <b>Total</b>
          </td>
          <td>
            <b>181.5</b>
          </td>
        </tr>
      </table>
    </div>
  ) : (
    <div className="schedules">
      <br />
      <hr />
      <a href="#su07">Summer 2007</a>|<a href="#fa07">Fall '07</a>|
      <a href="#sp08">Spring '08</a>
      <br />
      <a href="#su08">Summer 2008</a>|<a href="#fa08">Fall '08</a>|
      <a href="#sp09">Spring '09</a>
      <br />
      <hr />
      <section id="su07" />
      <table>
        <caption>Zac's Schedule for Summer 2007 [3 of 3 credits]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
        </tr>
        <tr>
          <td>5:30-8:30pm</td>
          <td>MBA6110 - Mgmt Tools (A)</td>
          <td />
          <td>MBA6110 - Mgmt Tools</td>
          <td />
        </tr>
        <tr>
          <td colSpan={5}>GPA: 4.0</td>
        </tr>
      </table>
      <br />
      <hr />
      <section id="fa07" />
      <table>
        <caption>Zac's Schedule for Fall 2007 [15(15)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
        </tr>
        <tr>
          <td>
            Session I<br />
            8/27-10/20
            <br />
            5:30-8:30pm
          </td>
          <td />
          <td />
          <td>MBA 6410 - Gbl Macro (A-)</td>
          <td>MBA 6120 - Org Behavior (B+)</td>
        </tr>
        <tr>
          <td>
            Session II
            <br />
            10/22-12/14
            <br />
            5:30-8:30pm
          </td>
          <td>MBA 6150 - Logistics 113 (A)</td>
          <td />
          <td>MBA 6140 - Marketing 112 (A-)</td>
          <td>MBA 6630 - Net &amp; IS 225 (A)</td>
        </tr>
        <tr>
          <td>Online</td>
          <td colSpan={4}></td>
        </tr>
        <tr>
          <td colSpan={5}>GPA: 3.78</td>
        </tr>
      </table>
      <br />
      <hr />
      <section id="sp08" />
      <table>
        <caption>Zac's Schedule for Spring 2008 [9(9)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
        </tr>
        <tr>
          <td>
            Session I<br />
            1/7-2/29
            <br />
            5:30-8:30pm
          </td>
          <td>MBA 6580 - Proj Mgmt(A)</td>
          <td></td>
          <td>MBA 6640 - Info Assurance (A)</td>
          <td></td>
        </tr>
        <tr>
          <td>
            Session II
            <br />
            3/3-5/1
            <br />
            5:30-8:30pm
          </td>
          <td>MBA 6310 - IT Enterprise (A)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Online</td>
          <td colSpan={4}></td>
        </tr>
        <tr>
          <td colSpan={5}>GPA: 3.86</td>
        </tr>
      </table>
      <br />
      <hr />
      <section id="su08" />
      <table>
        <caption>Zac's Schedule for Summer 2008 [3(3)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
        </tr>
        <tr>
          <td>
            Session I<br />
            6/9-8/1
            <br />
            5:30-8:30pm
          </td>
          <td>MBA 6020 - Accounting (A)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
      <br />
      <hr />
      <section id="fa08" />
      <table>
        <caption>Zac's Schedule for Fall 2008 [12(12)]</caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
        </tr>
        <tr>
          <td>
            Session I<br />
            8/25-10/18
            <br />
            5:30-8:30pm
          </td>
          <td></td>
          <td></td>
          <td>MBA 6210 - Mgmt Accounting (A)</td>
          <td></td>
        </tr>
        <tr>
          <td>
            Session II
            <br />
            10/20-12/12
            <br />
            5:30-8:30pm
          </td>
          <td></td>
          <td>MBA 6130 - Fin Mgmt (A)</td>
          <td></td>
          <td>MBA 6160 Applic. Decision Modeling (A)</td>
        </tr>
        <tr>
          <td>Online</td>
          <td colSpan={4}>BSAD 3200 - Legal Issues (A)</td>
        </tr>
      </table>
      <br />
      <hr />
      <section id="sp09" />
      <table>
        <caption>
          Zac's Schedule for Spring 2009 [4 completed (4 started)]
        </caption>
        <tr>
          <th>Time</th>
          <th>Mon</th>
          <th>Tues</th>
          <th>Wed</th>
          <th>Thur</th>
        </tr>
        <tr>
          <td>
            Session I<br />
            1/5-2/27
            <br />
            5:30-8:30pm
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>MBA 6180 - Strategic Mgmt D2-112 (DIXON) (A)</td>
        </tr>
        <tr>
          <td>
            Session II
            <br />
            3/2-5/1
            <br />
            5:30-8:30pm
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Online</td>
          <td colSpan={4}>MBA6800 - IT+S (A)</td>
        </tr>
      </table>
    </div>
  );
};
