import React from "react";
import "./App.css";
import { Header } from "./Components/Header";
import { Footer } from "./Components/Footer";
import { About } from "./Components/About";
import { Resume } from "./Components/Resume";
import { Contact } from "./Components/Contact";
import { Testimonials } from "./Components/Testimonials";
import { Portfolio } from "./Components/Portfolio";

const App: React.FC = (props) => {
  const [resumeData, setResumeData] = React.useState<any>({});
  React.useEffect(() => {
    const getResumeData = () => {
      fetch("resumeData.json")
        .then((res) => res.json())
        .then((data) => {
          setResumeData(data);
        })
        .catch(console.log);
    };
    getResumeData();
  }, []);

  return (
    <div className="App">
      <Header data={resumeData.main} />
      <About data={resumeData.main} />
      <Resume data={resumeData.resume} />
      <Portfolio data={resumeData.portfolio} />
      <Testimonials data={resumeData.testimonials} />
      <Contact data={resumeData.main} />
      <Footer data={resumeData.main} />
    </div>
  );
};

export default App;
