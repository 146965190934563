import React from "react";
import { Button } from "@patternfly/react-core";
import { SchoolSchedule } from "./school-schedule";

export const Resume: React.FC<{ data: any }> = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalOpen2, setIsModalOpen2] = React.useState(false);

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);
  const handleModalToggle2 = () => setIsModalOpen2(!isModalOpen2);

  if (props.data) {
    var skillmessage = props.data.skillmessage;
    var education = props.data.education.map((education: any) => {
      return (
        <div key={education.school + education.degree}>
          <span className="horizontal">
            <img src={education.logo} alt={education.logo} width="100px" />
            <h3>{education.school}</h3>
          </span>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });
    var work = props.data.work.map((work: any) => {
      return (
        <div key={work.company}>
          <span className="horizontal">
            <img src={work.logo} alt={work.logo} width="100px" />
            <h3>{work.company}</h3>
          </span>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <p>{work.description}</p>
        </div>
      );
    });
    var skills = props.data.skills.map((skills: any) => {
      var className = "bar-expand " + skills.name.toLowerCase();
      return (
        <li key={skills.name}>
          <span style={{ width: skills.level }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });
  }

  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
            <Button key={`show-csm-schedule`} onClick={handleModalToggle}>
              CSM Schedule
            </Button>
            <Button key={`show--wsu-schedule`} onClick={handleModalToggle2}>
              | WSU Schedule
            </Button>
          </div>
          {isModalOpen && (
            <div className="popup-modal">
              <div>
                <SchoolSchedule school="csm" />
              </div>
              <Button
                key={`show-schedule`}
                variant="primary"
                onClick={handleModalToggle}
              >
                Close
              </Button>
            </div>
          )}
          {isModalOpen2 && (
            <div className="popup-modal">
              <div>
                <SchoolSchedule school="wsu" />
              </div>
              <Button
                key={`close-csm-schedule`}
                variant="primary"
                onClick={handleModalToggle2}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>Work</span>
          </h1>
        </div>

        <div className="nine columns main-col">{work}</div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <p>{skillmessage}</p>

          <div className="bars">
            <ul className="skills">{skills}</ul>
          </div>
        </div>
      </div>
    </section>
  );
};
